body,
html {
	height: 100%;
	overscroll-behavior-y: none;
	font-size: 16px;

	@media (min-width: 780px) {
		font-size: 16px;
	}
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
	font-size: 16px;
	hyphens: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior-y: none;
}

body.dyslexia {
	font-family: 'Lexend', sans-serif !important;
}

a {
	color: rgb(61, 61, 61);
	font-weight: bold;
}

* {
	box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

img.note-float-right {
	padding-left: 4px;
}

img.note-float-left {
	padding-right: 4px;
}

/**
This background is specially added for ipad / android to deal
with safe area and the trouble they are causing..
**/
body.app-mode {
	background-size: 100% 100%;
	background-position: 0px 0px;
	background-image: linear-gradient(90deg, #0c497a calc(59px + env(safe-area-inset-left)), white calc(59px + env(safe-area-inset-left)));
}

h1 span.verse-inline,
h2 span.verse-inline,
h3 span.verse-inline,
h4 span.verse-inline,
h5 span.verse-inline,
h6 span.verse-inline {
	display: block !important;
}

h1 .verse-block,
h2 .verse-block,
h3 .verse-block,
h4 .verse-block,
h5 .verse-block,
h6 .verse-block {
	display: block !important;
}